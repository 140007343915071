import React from "react";
import { header } from "../../profile";

const Header = () => {

	const toggleDarkMode = () => {
		document.documentElement.classList.toggle("dark-mode");
		document
			.getElementById("not-dark")
			.classList.toggle("inverse-dark");
		document
			.getElementById("not-dark2")
			.classList.toggle("inverse-dark");
		var x = document.getElementsByClassName("img-pro");
		for (let i = 0; i < x.length; i += 1) {
			x.item(i).classList.toggle("inverse-dark");
		}

		if (document.documentElement.classList.contains("dark-mode"))
			localStorage.setItem("mode", "Dark");
		else localStorage.setItem("mode", "Light");
	};

	return (
		<div>
			<div className="Header">
				<h1>{`I'm ${header.name}`}</h1>
				<p className="line-1 anim-typewriter">
					and this is my Webpage...{" "}
				</p>
				<label className="switch">
					<input
						id="mode-switch"
						onClick={(e) => toggleDarkMode(e)}
						type="checkbox"
					/>
					<span className="slider round"></span>
				</label>
			</div>
		</div>
	);
};

export default Header;
