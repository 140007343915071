import React from "react";
import { contact, section5Title, social } from "../../profile";

const Contact = () => {
	return (
		<div className="parallax">
			<div
				data-aos="zoom-in-up"
				data-aos-once="true"
				className="git-form">
				<>
					<div className="git-head-div text-center mx-auto">
						<h1 id="Contact" className="git-head">
							{section5Title}
						</h1>
					</div>
				</>
				<div className="container">
					<div className="git-cont row">
						<div className="git-text">
							<p className="lead">{contact.pitch}</p>
							<div className="d-flex justify-content-center align-items-center flex-column">
								<div className="inline-block">
									{social.linkedin && (
										<a
											title="Visit Linkedin profile"
											rel="noopener noreferrer"
											target="_blank"
											href={social.linkedin}>
											<i className="fab fa-linkedin"></i>
										</a>
									)}
									<br />
								</div>
								{social.resume && (
									<a
										title="Download Resume"
										href={social.resume}
										download>
										<i className="fas fa-download"></i>
									</a>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
			<p id="not-dark" className="Copy">
				2021 © Copyright <strong>{contact.copyright}</strong>. All
				Rights Reserved
			</p>
		</div>
	);
};

export default Contact;
