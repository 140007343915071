const animation = {
	animate: true,
	duration: 750,
	once: false,
};

const header = {
	name: "Luis Mendez",
};
const background = {
	type: "Particle",
};

const section2title = "About Me";

const about = {
	paragraph:
		"As a detail-oriented software developer my primary goal is to apply my technical skills throughout the full software life cycle; ensuring production and delivery of products and services that meet client specifications. ",
};

const skillsBar = [
	{
		name: "HTML5",
		svg: "M0 32l34.9 395.8L191.5 480l157.6-52.2L384 32H0zm308.2 127.9H124.4l4.1 49.4h175.6l-13.6 148.4-97.9 27v.3h-1.1l-98.7-27.3-6-75.8h47.7L138 320l53.5 14.5 53.7-14.5 6-62.2H84.3L71.5 112.2h241.1l-4.4 47.7z",
		faClass: "fab fa-html5",
	},
	{
		name: "CSS3",
		
		faClass: "fab fa-css3",
	},
	{
		name: "Javascript",
		faClass: "fab fa-js",
	},
	{
		name: "SASS",
		faClass: "fab fa-sass",
	},
	{
		name: "Node",
		faClass: "fab fa-node",
	}
];

const section3Title = "Past Projects";

const projects = [
	{
		id: "project1",
		name: "Resilience",
		skills: ["HTML, CSS, JS"],
		url: "https://resilience.luism.dev",
	},
	{
		id: "project2",
		name: "Omnifoods",
		skills: ["HTML, CSS"],
		url: "https://tkwp.luism.dev",
	},
	{
		id: "project3",
		name: "Gaming Nexus",
		skills: ["HTML, CSS, JS"],
		url: "https://gnexus.luism.dev",
	},
	{
		id: "project4",
		name: "Natours",
		skills: ["HTML, CSS, JS"],
		url: "https://natours.luism.dev",
	},
	{
		id: "project5",
		name: "Nexter",
		skills: ["HTML, CSS"],
		url: "https://nexter.luism.dev",
	},
	{
		id: "project6",
		name: "Typing Game",
		skills: ["HTML, CSS, JS"],
		url: "https://typing.luism.dev",
	},
];

const section5Title = "Get in Touch";

const contact = {
	pitch:
		"If you want to contact me you can follow the link to my Linkedin profile where you can see more information about me, also you can get my resume on the download link below. Thank You!!",
	copyright: "Luis Mendez",
	contactUrl: "",
};

const social = {
	linkedin: "https://www.linkedin.com/in/luis-enrique-mendez-reverol-15839b221/",
	resume: "https://drive.google.com/file/d/1Qs4vHoZo1dECU7t-XbVq7NOykuWejSdv/view?usp=sharing",
};

export {
	animation,
	header,
	background,
	about,
	skillsBar,
	projects,
	contact,
	social,
	section2title,
	section3Title,
	section5Title,
};
